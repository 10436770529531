import Heading2 from "@/lib/Layout/Wrappers/Heading2";
import Heading3 from "@/lib/Layout/Wrappers/Heading3";
import { motion } from "framer-motion";
import { useRouter } from "next/dist/client/router";
import React, { ReactNode, useEffect, useState } from "react";
import Image from "next/image";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import useZustandStore from "@/lib/store/useZustandStore";

const HeroSectionNew = () => {
  const [resetSlide, setResetSlide] = useState(true);
  useEffect(() => {
    const i1 = setInterval(() => {
      setResetSlide(false);
      setResetSlide(true);
    }, 10000);
    return () => clearInterval(i1);
  });

  return (
    <div className="flex flex-col w-screen overflow-x-hidden">
      <div className="flex gap-4 md:gap-8 flex-col justify-center text-center items-center mb-8 ">
        <Heading2 className="!text-4xl md:text-7xl font-medium lg:flex gap-3 text-center">
          {" "}
          Live Longer Healthier<div className=" text-primary ">Lives</div>
        </Heading2>
        <div className="flex flex-wrap justify-center gap-2 text-center">
          <h1 className="font-normal text-xl lg:text-2xl ">
            Doctor-led holistic healthcare programs.
          </h1>
          <p className="font-normal text-xl lg:text-2xl">
            Start your AI-supported health journey today
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-0 lg:gap-24 ">
        <NonMobileMediaWrapper>
          <div className="w-screen flex justify-start flex-col ">
            {resetSlide && (
              <motion.div className=" flex gap-8 w-[4000px]">
                <div className="flex gap-8">
                  {" "}
                  {newCards.map((el, idx) => (
                    <LandingCard
                      key={el.title}
                      card={el}
                      id={idx}
                      isMobile={false}
                    />
                  ))}
                </div>
                <div className="flex gap-8">
                  {" "}
                  {newCards.map((el, idx) => (
                    <LandingCard
                      key={el.title + idx}
                      card={el}
                      id={idx}
                      isMobile={false}
                    />
                  ))}
                </div>
                <div className="flex gap-8">
                  {" "}
                  {newCards.map((el, idx) => (
                    <LandingCard
                      key={el.title + idx + idx}
                      card={el}
                      id={idx}
                      isMobile={false}
                    />
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        </NonMobileMediaWrapper>
        <MobileMediaWrapper>
          <motion.div className=" flex gap-8">
            <div className="flex gap-8">
              {newCards.map((el, idx) => (
                <LandingCard
                  key={el.title}
                  card={el}
                  id={idx}
                  isMobile={true}
                />
              ))}{" "}
            </div>
          </motion.div>
        </MobileMediaWrapper>
        <Band isLandingPage={true} />
      </div>
    </div>
  );
};

export default HeroSectionNew;

const MobileMediaWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div className="flex lg:hidden  overflow-x-scroll overflow-y-hidden h-[260px]  items-start">
      {children}
    </motion.div>
  );
};
const NonMobileMediaWrapper = ({ children }: { children: ReactNode }) => {
  return <motion.div className="hidden lg:flex ">{children}</motion.div>;
};

export const Band = ({ isLandingPage }: { isLandingPage?: boolean }) => {
  const handleClick = (route: string) => {
    const component = document.querySelector(route);
    component?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`flex gap-8 justify-center flex-wrap pb-8 ${
        isLandingPage ? "" : "cursor-pointer"
      }`}
    >
      {" "}
      {landingMap.map((el, idx) => {
        return (
          <div
            key={idx}
            className="flex flex-col items-center gap-8 w-36 "
            onClick={() => handleClick(el.route)}
          >
            <div className="h-14 w-28 relative ">
              <Image
                src={el.image}
                fill
                style={{ objectFit: "contain" }}
                alt={`${el.text} + "icon"`}
              />
            </div>
            <p className="text-center">{el.text}</p>
          </div>
        );
      })}
    </div>
  );
};

const landingMap = [
  {
    text: "Ring",
    image: "https://assets.bonatra.com/package_detail/Ring%20X1.webp",
    route: "#doctor",
  },
  {
    text: "Continous Glucose Monitoring",
    image: "https://assets.bonatra.com/package_detail/CGM%20Band.webp",
    route: "#cgmplan",
  },
  {
    text: "Real-Time Insights",
    image: "https://assets.bonatra.com/package_detail/insight%201.webp",
    route: "#cgmplan",
  },
  {
    text: "5000+ Happy users",
    image:
      "https://assets.bonatra.com/package_detail/sentiment_very_satisfied_FILL0_wght400_GRAD0_opsz24%201.webp",
    route: "#testimonials",
  },
  {
    text: "Personalized Solutions",
    image:
      "https://assets.bonatra.com/package_detail/Personalized%20Solutions.webp",
    route: "#benefits",
  },

  // {
  //     text: 'Natural Lifestyle Treatment', image: 'https://assets.bonatra.com/package_detail/pill_FILL0_wght300_GRAD0_opsz48+%281%29+1.webp',route: "#cgmplan"
  // },
  {
    text: "4.5+ Star Ratings",
    image:
      "https://assets.bonatra.com/package_detail/Cutomer%20Rating%20Star.webp",
    route: "#cgmplan",
  },
];
const colorMap = [
  "bg-rose-200",
  "bg-blue-200",
  "bg-green-200",
  "bg-yellow-200",
  "bg-purple-200",
  "bg-pink-200",
  "bg-rose-200",
  "bg-blue-200",
  "bg-green-200",
  "bg-yellow-200",
  "bg-purple-200",
  "bg-pink-200",
];

const LandingCard = ({
  card,
  id,
  isMobile,
}: {
  card: (typeof newCards)[0];
  id: number;
  isMobile: boolean;
}) => {
  const navigation = useRouter();
  const setProductFilters = useZustandStore((state) => state.setProductFilters);
  const productFilters = useZustandStore((state) => state.productFilters);

  return (
    <motion.div
      onClick={() => {
        productFilters.brand = [];
        if (card.title === "Bonatra Delights") {
          productFilters.brand = ["Diabexy", "DiabeSmart", "Protein Chef"];
        }
        setProductFilters(card.filters);
        navigation.push(card.route);
      }}
      className={`shadow-lg h-[200px] lg:h-[200px] ${colorMap[id]} rounded-t-lg`}
      animate={{ x: isMobile ? 0 : [0, -4000] }}
      transition={{ duration: 100, ease: "linear", repeat: Infinity }}
    >
      <motion.div className="relative w-[150px] h-[150px] lg:w-[200px] lg:h-[180px] ">
        <Image
          src={card.image}
          alt={card.title}
          fill
          style={{ objectFit: "contain" }}
        />
      </motion.div>
      <div className="relative z-100 rounded-b-lg cursor-pointer">
        <div className=" bg-white  w-full h-[60px] lg:h-[70px] p-4 lg:p-8 rounded-lg  shadow-xl rounded-t-none flex  justify-between items-center gap-4 ">
          <h2 className="text-sm md:text-3xl lg:text-lg flex items-center font-semibold ">
            {card.title}
          </h2>
          <BsFillArrowRightCircleFill color="#000" className="text-3xl " />
        </div>
      </div>
    </motion.div>
  );
};

// const LandingCard = ({ card, id, isMobile }: { card: typeof newCards[0], id: number, isMobile: boolean }) => {
//     const navigation = useRouter()
//     return <motion.div className={` flex flex-col justify-between  rounded-lg h-[400px] w-[200px] lg:h-[600px] lg:w-[350px] pt-12 `} animate={{ x: isMobile ? 0 : [0, -4000] }} transition={{ duration: 100, ease: "linear", repeat: Infinity }}>
//         <motion.div className={`absolute ${colorMap[id]} lg:w-[350px] lg:h-[500px] rounded-lg shadow-lg`} animate={{ y: isMobile ? 50 : 100 }}></motion.div>
//         <motion.div className='w-[200px] h-[250px] lg:w-[350px] lg:h-[400px] ' animate={{ y: isMobile ? -20 : -40 }} >
//             <Image src={card.image} alt={card.title} fill style={{ objectFit: "contain" }} />
//         </motion.div>
//         <div className='relative z-100 shadow-xl rounded-b-lg'>
//             <div className='bg-white absolute -bottom-12 h-72 p-4  rounded-lg rounded-t-none flex flex-col justify-between gap-4'>
//                 <Heading3>{card.title}</Heading3>
//                 <Paragraph className='font-normal'>{card.paragraph}</Paragraph>
//                 <Paragraph className='font-bold'>Starting from <span className='text-primary'>{formatPrice(card.startingPrice)}</span> only/-</Paragraph>
//                 <button onClick={() => { navigation.push("/products") }} className="bg-gray-900 p-4 rounded-xl text-white" >View Plans</button>
//             </div>
//         </div>

//     </motion.div>
// }

const newCards = [
  {
    title: "Diabetes",
    paragraph:
      "Reversing diabetes is possible with the technology, lifestyle changes, and medical attention.",
    image: "https://assets.bonatra.com/package_detail/Diabetes.webp",
    startingPrice: 2500,
    tag: null,
    filters: "Bonatra X",
    route: "/package/one-month",
  },

  {
    title: "PCOD / PCOS",
    paragraph:
      "Manage your PCOS/PCOD symptoms with lifestyle changes and monitoring.",
    image: "https://assets.bonatra.com/package_detail/Thyroid.webp",
    startingPrice: 2500,
    tag: null,
    filters: "Bonatra X",
    route: "/package/thyroid-pcod",
  },
  {
    title: "Fitness",
    paragraph:
      "Get fit and stay healthy with lifestyle changes and regular exercise.",
    image: "https://assets.bonatra.com/package_detail/Fitness.webp",
    startingPrice: 2500,
    tag: null,
    filters: "Bonatra X",
    route: "/package/fitness",
  },
  // {
  //     title: "Blood Pressure",
  //     paragraph: 'Maintain your blood pressure with lifestyle changes.',
  //     image: 'https://assets.bonatra.com/package_detail/Hypertension+(1).webp',
  //     startingPrice: 2500,
  //     tag: null,
  //     filters: "Bonatra X",
  //     route: "/package/blood-pressure"
  // },
  // {
  //     title: "Thyroid",
  //     paragraph: 'Managing your thyroid health requires lifestyle changes and regular monitoring for best results.',
  //     image: 'https://assets.bonatra.com/package_detail/PCOD+PCOS.webp',
  //     startingPrice: 2500,
  //     tag: null,
  //     filters: "Bonatra X",
  //     route: "/package/thyroid-pcod"
  // },
  {
    title: "Starter Package",
    paragraph:
      "Stay ahead of potential health issues with a comprehensive full body checkup.",
    image: "https://assets.bonatra.com/package_detail/Starter%20Package.webp",
    startingPrice: 1999,
    tag: null,

    filters: "Lab Test and Consultation",
    route: "/package/starter",
  },
  {
    title: "Bonatra Delights",
    paragraph:
      "Shop for healthy, low-glycemic foods at our store for a healthier lifestyle.",
    image:
      "https://assets.bonatra.com/package_detail/Bonatra%20Delights%20(2)%20(1).webp",
    startingPrice: 2500,
    tag: null,
    filters: "Lo! Low Carb Delights",
    route: "all-products",
  },
  {
    title: "Supplements",
    paragraph:
      "Supplement your healthy diet with the right vitamins and minerals to support your overall health.",
    image: "https://assets.bonatra.com/package_detail/Supplements.webp",
    startingPrice: 2500,
    tag: "Lowest Price",
    filters: "Bonatra Supplements",
    route: "all-products",
  },
];
