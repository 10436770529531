import Section from "@/lib/Layout/Wrappers/Section";
import { Band } from "../HeroSection/HeroSectionNew";

const IconBand = () => {
  return (
   <div className="px-16 md:px-8">
      <Band isLandingPage={false}/>
      </div>
  );
};

export default IconBand;
